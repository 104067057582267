import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/paginated-layout.js";
import { Subpage, Callout } from "course-components";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Subpage slug="layout-analysis" mdxType="Subpage">
      <h3>{`Layout Analysis`}</h3>
      <p>{`You now know a good bit about how to work with CSS. We've discussed how to select and apply styles to HTML elements, how to style content, work with the box model, and, finally, how to build some basic layouts with all these skills in combination with basic float and clear settings.`}</p>
      <p>{`Now I'd like to outline for you a process you can follow for larger more complex layouts. It begins with layout analysis, where you first inspect your comp in order to plan the approach you'll use with CSS. We'll make identify rows and columns that will translate to specific CSS settings.`}</p>
      <h4>{`Definitions`}</h4>
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Tiles`}</strong>{` are discrete groups of elements that are laid out beside other groups of elements, or are grouped with elements that do. For the sake of layout analysis, I suggest highlighting tiles using a blue line or box.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Containers`}</strong>{` are groups of tiles. For the sake of layout analysis, I suggest highlighting columns using a red line or box. While there are many elements that act as containers in a given document, `}<em parentName="p">{`layout`}</em>{` Containers only need to be marked as such when they contain two or more tiles.`}</p>
        </li>
      </ul>
      <h4>{`Process`}</h4>
      <p>{`With a particular design comp open, follow this process in order to identify tiles and containers:`}</p>
      <ol>
        <li parentName="ol"><em parentName="li">{`Sketch`}</em>{`: Sketch your layout on paper in light pencil or light grey ink.`}</li>
        <li parentName="ol"><em parentName="li">{`Group`}</em>{`: Identify groups of elements and outline them with a dotted black box. Start by identifying small groups, and move outwards to larger groups or even groups that contain smaller groups.`}</li>
        <li parentName="ol"><em parentName="li">{`Identify tiles`}</em>{`: Identify either individual elements or groups that are presented beside other groups as tiles and highlight them with a blue box.`}</li>
        <li parentName="ol"><em parentName="li">{`Identify containers`}</em>{`: Identify containers for sets of related tiles by highlighting them with a red box. There are a few nuances to consider here:`}<ul parentName="li">
            <li parentName="ul">{`The simplest container is one that neatly naturally wraps around an obvious set of tiles, such as a container around a list of product image thumbnails.`}</li>
            <li parentName="ul">{`Sometimes the best container is a group that contains groups that you recognized as tiles and others that you didn't. In order for this container to be such a container, we'll have to realize that the groups we didn't call tiles before can actually be tiles, but just set to fill the container's width. `}<em parentName="li">{`In this case, identify the other groups as tiles by adding a blue box around them as well.`}</em></li>
            <li parentName="ul">{`Finally, some containers are themselves tiles. They are tiles in that they themselves are presented beside other groups and part of a larger container. They are containers in that they contain nested tiles. `}<em parentName="li">{`In this case, identify the tile as a tile/container combo by adding a dotted red box outside the blue box already in place.`}</em></li>
          </ul></li>
      </ol>
      <h4>{`Exceptions`}</h4>
      <p>{`Watch for the following exceptions as you analyze your comp:`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "left"
            }}>{`Description`}</th>
            <th parentName="tr" {...{
              "align": "left"
            }}>{`Sample`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><strong parentName="td">{`Background layering`}</strong>{` — If you have a section in the comp where a set of text is laid out overlapping with the image as a background, the image should be seen as a background of an element and not as a tile for layout analysis. Just be certain that there is really one set of content with each element stacked on top of the other; otherwise, there might be tiles inside of a container that lay over the image.`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><img parentName="td" {...{
                "src": "/images/la-x-faux-col.png",
                "alt": null
              }}></img></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><strong parentName="td">{`Natural Float`}</strong>{` — If you have a section on your comp where an image or set of elements is hanging out on one side or the other of some text that is just flowing around it, you probably don't have a true tile, but, rather, just a natural float. Use the `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`float`}</code>{` property to create this effect on that element or group.`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><img parentName="td" {...{
                "src": "/images/la-x-natural-float.png",
                "alt": null
              }}></img></td>
          </tr>
        </tbody>
      </table>
    </Subpage>
    <Subpage slug="building-tiles-and-containers" mdxType="Subpage">
      <h3>{`Building Tiles and Containers`}</h3>
      <p>{`Once you've completed the Layout Analysis you are ready to move into building out the comp, a phase I call Reconstruction.`}</p>
      <p>{`The rows and columns you identified in Layout Analysis can be built out by applying the following style sets to each corresponding element:`}</p>
      <p><strong parentName="p">{`Containers:`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`[container selector]`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`display`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`flex`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`/*
        Other settings optional such as
        - justify-content
        - flex-flow
        - align-items    
    */`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p><strong parentName="p">{`Tiles:`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`[tile(s) selector]`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`box-sizing`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`border-box`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`/* width setting */`}</span>{`
    `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`/* margin and padding as needed */`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`We'll look at a series of common layout patterns in Lesson 12.`}</p>
    </Subpage>
    <Subpage slug="building-layouts" mdxType="Subpage">
      <h3>{`Process for Building Layouts`}</h3>
      <p>{`With this basic understanding of the CSS you need in order to build rows and columns you must also note that the they don't just invent themselves in your markup. As you work to create the markup your layout demands, start with content markup as we discussed in Lessons 1–3, and 5. Then use the sectioning tags and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<div>`}</code>{` tags we learned in Lesson 4 to add groups wherever they're needed. If a row or column includes multiple elements, consider using the most semantic grouping element you can in order to create such a group.`}</p>
      <h4>{`Suggested Process`}</h4>
      <p>{`Use this process to complete the reconstruction in a methodical, efficient way:`}</p>
      <ol>
        <li parentName="ol">
          <p parentName="li">{`First, create simple, clean, semantic HTML based on the content in your comp. You might also benefit from some simple grouping with `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`<div>`}</code>{` tags. Use the `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`class`}</code>{` attribute to distinguish such a group from other groups.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Through Layout Analysis you identified tiles and containers. With basic HTML in place you must now ensure that your simple, semantic HTML is also compatible with the tile and container structure you identified.`}</p>
          <ul parentName="li">
            <li parentName="ul"><strong parentName="li">{`Tiles`}</strong>{` should alway be a single element. This means that you will need to ensure that each thing you marked as a tile in your analysis is either already a single element or is a collection of elements grouped together by a single `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`<div>`}</code>{` or other more semantic option.`}</li>
            <li parentName="ul"><strong parentName="li">{`Containers`}</strong>{` should be the direct parent element of the set of tiles you mean to display as tiles. Often these will naturally be the closest grouping element, but if a simple single element does not wrap around your tiles, you might consider adding such an element. `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`<div>`}</code>{` with a meaningful class is usually a safe option here.`}</li>
          </ul>
          <p parentName="li">{`Bottom line here is that you must have markup that matches your analysis: Tiles must be a single element and the container must be the direct parent of all the related tiles.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`For each container you identified, you must select it and turn on `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`display:flex`}</code>{`. You might also benefit from one or more of the following `}<em parentName="p">{`also set on this container`}</em>{`:`}</p>
          <ul parentName="li">
            <li parentName="ul">
              <p parentName="li"><code parentName="p" {...{
                  "className": "language-text"
                }}>{`flex-flow`}</code>{` — if your set of tiles spans more than one "row" in the layout turn this setting on, and set it to `}<code parentName="p" {...{
                  "className": "language-text"
                }}>{`row wrap`}</code>{`. There are other settings for this property, but this is the most common. You'll likely want to control such an element's width to ensure tiles it contains wrap in the desired space.`}</p>
            </li>
            <li parentName="ul">
              <p parentName="li"><code parentName="p" {...{
                  "className": "language-text"
                }}>{`justify-contents`}</code>{` — this setting helps with `}<em parentName="p">{`some`}</em>{` spacing scenarios. You can experiment with the following to see if the desired results fit your comp closely enough:`}</p>
              <ul parentName="li">
                <li parentName="ul">
                  <p parentName="li"><code parentName="p" {...{
                      "className": "language-text"
                    }}>{`center`}</code>{` — helpful for creating a center-aligned flow for the set of elements.`}</p>
                </li>
                <li parentName="ul">
                  <p parentName="li"><code parentName="p" {...{
                      "className": "language-text"
                    }}>{`space-between`}</code>{` — helpful when you have a few items in a single row and want the outer two hugging the outer bounds and the inner ones spaced evenly from each other.`}</p>
                </li>
                <li parentName="ul">
                  <p parentName="li"><code parentName="p" {...{
                      "className": "language-text"
                    }}>{`space-around`}</code>{` — helpful when you have an even gutter between each tile and space on the outside gutters that is half of the inner gutter.`}</p>
                  <p parentName="li">{`If these do not suffice, you may need to move on to specify spacing on the tiles themselves.`}</p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Move to the tiles inside this container and, if needed, specify a `}<code parentName="p" {...{
              "className": "language-text"
            }}>{`width`}</code>{` setting to ensure they each occupy the desired space. In addition, if some of the flex box settings from the container do not have the desired effect for spacing these elements, add margin and/or padding and other box model settings as needed.`}</p>
        </li>
      </ol>
      <p>{`Continue to refine styles overall to ensure all aspects match the desired style.`}</p>
    </Subpage>
    <Subpage slug="layout-patterns-intro" mdxType="Subpage">
      <h3>{`Layout Patterns Introduction`}</h3>
      <p>{`Most layouts on the web can be created easily with the flex box settings. There are a relatively small number of patterns that can be applied to such situations.`}</p>
      <p>{`First conduct layout analysis in order to identify rows and columns. Then, consider which of the patterns that follow fits each row the best. Apply the overall settings but customize the particular values as needed.`}</p>
    </Subpage>
    <Subpage slug="row-on-its-own" mdxType="Subpage">
      <h3>{`Layout Pattern A: Row on its own`}</h3>
      <p>{`The simplest pattern to build out is a row of content that contains no actual columns; all elements inside the row stack on top of each other according to default behavior. In such a case `}<strong parentName="p">{`DO NOT`}</strong>{` use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`display:flex`}</code>{` on the row; I'm calling these out to show you many things that can be done without flex box.`}</p>
      <h4>{`A1: Row spanning full width of outer container`}</h4>
      <p>{`A row on its own might simply span all or most of the width of its container:`}</p>
      <p><img parentName="p" {...{
          "src": "/images/layout-patterns-A1.png",
          "alt": null
        }}></img></p>
      <p><strong parentName="p">{`HTML:`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`intro`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Lorem Ipsum Dolor Sit Amet.`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Duis vel fermentum tortor...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p><strong parentName="p">{`CSS:`}</strong>{` only add settings for border and spacing if needed. DO NOT use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`display:flex;`}</code>{` on such a row.`}</p>
      <h4>{`A2: Row with restricted width`}</h4>
      <p>{`A row on its own might also have a restricted width but nothing on either side of it:`}</p>
      <p><img parentName="p" {...{
          "src": "/images/layout-patterns-A2.png",
          "alt": null
        }}></img></p>
      <p><strong parentName="p">{`HTML:`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`followup-1`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Sed mollis, diam vitae`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Suspendisse vel dui lobortis...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Suspendisse potenti. Mauris...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p><strong parentName="p">{`CSS:`}</strong>{` Set the restricted width and spacing as needed. In this case:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`.followup-1`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`0 0 20px 120px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`760px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`padding`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`30px 0 20px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <h4>{`A3: Centered row with restricted width`}</h4>
      <p>{`A row on its own with a restricted width might be intended to appear "centered" inside its overall container. Such a setup is simpler:`}</p>
      <p><img parentName="p" {...{
          "src": "/images/layout-patterns-A3.png",
          "alt": null
        }}></img></p>
      <p><strong parentName="p">{`HTML:`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`followup-2`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/followup-2.png`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Integer tincidunt, sem pharetra`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Sapien lectus vestibulum...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p><strong parentName="p">{`CSS:`}</strong>{` Simply set the restricted width and then use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`auto`}</code>{` for the left and right `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`margin`}</code>{` such as:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`.followup-2`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`20px auto 40px auto`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`760px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`You'll often see me use a width plus `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`margin:0 auto`}</code>{` to center a layout element.`}</p>
      <h4>{`A4: Row on its own with background image`}</h4>
      <p>{`Another common variation of the row on its own is the "faux column" we discussed earlier. Here there is still only content inside the row stacked one on top of the other, but it feels different because there is also a background image that adds the impression of another column or other content. Whether the content is centered or offset in some way, we'll have to use padding to create the offset. We `}<strong parentName="p">{`cannot`}</strong>{` use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`auto`}</code>{` with `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`padding`}</code>{` as we did with `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`margin`}</code>{` but must instead use a valid dimension using pixels or percentage. Sometimes a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`height`}</code>{` setting also comes in handy.`}</p>
      <p><img parentName="p" {...{
          "src": "/images/layout-patterns-A4a.png",
          "alt": null
        }}></img></p>
      <p><strong parentName="p">{`HTML:`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`followup-3`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Integer id sapien a ipsum faucibus`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Integer dapibus nibh quis...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Ut et mauris accumsan...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Nullam et velit quis enim...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Duis congue ligula sem, sit...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Cras condimentum ut quam...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Duis congue ligula sem, sit...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p><strong parentName="p">{`CSS:`}</strong>{` Here we assume `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`width`}</code>{` is either `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`100%`}</code>{` or the default `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`width`}</code>{` value and just adding `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`padding`}</code>{` and a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`height`}</code>{` setting. `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`box-sizing:border-box`}</code>{` also often proves useful here.`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`.followup-3`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`background`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`black
        `}<span parentName="code" {...{
              "className": "token url"
            }}><span parentName="span" {...{
                "className": "token function"
              }}>{`url`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`images/followup-3.png`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span></span>{`
        left top no-repeat`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`box-sizing`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`border-box`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`padding`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`30px 20px 0 520px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`height`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`360px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p><img parentName="p" {...{
          "src": "/images/layout-patterns-A4b.png",
          "alt": null
        }}></img></p>
      <p><strong parentName="p">{`HTML:`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`hero`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Sed rutrum ligula.`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`#`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Ac lacus mattis `}<span parentName="code" {...{
              "className": "token entity",
              "title": "»"
            }}>{`&raquo;`}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p><strong parentName="p">{`CSS:`}</strong>{` Here as in the previous example we use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`padding`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`height`}</code>{` with the background settings to achieve the overall offset. But we've also center-aligned the text for the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<h2>`}</code>{` and the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<a>`}</code>{`.`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`.hero`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`background`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span><span parentName="code" {...{
              "className": "token url"
            }}><span parentName="span" {...{
                "className": "token function"
              }}>{`url`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`images/hero.png`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span></span>{`
        top center no-repeat`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`box-sizing`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`border-box`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`padding`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`520px 420px 0`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`height`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`720px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.hero a`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`border`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`1px solid white`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`display`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`block`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`text-align`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`center`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.hero h2`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`text-align`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`center`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    </Subpage>
    <Subpage slug="row-with-columns" mdxType="Subpage">
      <h3>{`Layout Pattern B: Row with columns`}</h3>
      <p>{`Building off of the basics or working with rows, we next examine patterns that make use of columns in various forms.`}</p>
      <h4>{`B1. Equal-sized columns`}</h4>
      <p>{`When all the columns inside a row are the same size the solution can be very simple, especially if all the items are the same element or have a class in common. Just use a nested selector in order to provide styles for the whole set of elements.`}</p>
      <p><img parentName="p" {...{
          "src": "/images/layout-patterns-B1a.png",
          "alt": null
        }}></img></p>
      <p><strong parentName="p">{`HTML:`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`ul`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`categories`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`#`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/category-1.png`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Sed convallis magna`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Sit amet orci finibus...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`#`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/category-2.png`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Ut non condimentum`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Proin finibtus eros risus.`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`#`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/category-3.png`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Ut dignissim in odio`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Aliquam vehicula quis arcu...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p><strong parentName="p">{`CSS:`}</strong>{` The `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`flex:1`}</code>{` setting is a flexible way to set them all to the same size but feel free to set a pixel or percentage value instead if you like.`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`.categories`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`display`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`flex`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`justify-content`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`space-around`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`0`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`padding`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`0`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.categories li`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`flex`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`1`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`/* or a width setting */`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin-bottom`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`20px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`list-style`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`none`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`/* disables bullets */`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`Sometimes you might find that you need to adjust either the first item in the set or the last item in the set (such as to cancel a border or some spacing). Keep in mind that you can set up the overall settings with a broad selector as described above but then provide an override setting using the :first-child or :last-child pseudo-class selectors.`}</p>
      <p><img parentName="p" {...{
          "src": "/images/layout-patterns-B1b.png",
          "alt": null
        }}></img></p>
      <p><strong parentName="p">{`HTML:`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`leaders`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`lead`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Curabitur augue libero`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Pellentesque eget ultrices...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        ...
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`lead`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Praesent id purus`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Nec sem bibendum bibendum at...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        ...
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`lead`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Suspendisse maximus`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Tempor felis in volutpat...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        ...
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p><strong parentName="p">{`CSS:`}</strong>{` Here we put a border on the right side of most elements but cancel it on the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`:last-child`}</code>{`. As a result we have reverted to using `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`padding`}</code>{` to space the items side-to-side instead of `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`justify-contents`}</code>{` on the container.`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`.leaders`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token property"
            }}>{`display`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`flex`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.lead`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`flex`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`1`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`/* or a width setting */`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`padding`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`20px 20px 0`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`20px 0`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`border-right`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`1px solid #869396`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.lead:last-child`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token property"
            }}>{`border-right`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`none`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <h4>{`B2: Uneven columns`}</h4>
      <p>{`On the other hand, if the columns inside the row are different sizes, its best to target each individually and set the desired size.`}</p>
      <p><img parentName="p" {...{
          "src": "/images/layout-patterns-B2.png",
          "alt": null
        }}></img></p>
      <p><strong parentName="p">{`HTML:`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`features`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`feature left`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/feature-left.png`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`#`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`feature right`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/feature-right.png`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`#`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p><strong parentName="p">{`CSS:`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token comment"
            }}>{`/* Key settings for the layout */`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.features`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`display`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`flex`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`justify-content`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`space-between`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.features .left`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`480px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.features .right`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`680px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`/* Other settings you might find helpful here */`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.features a`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`padding`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`20px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`display`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` block`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`color`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`white`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.features img`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token property"
            }}>{`display`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`block`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.features .left a`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token property"
            }}>{`background-color`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`#a2e500`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.features .right a`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token property"
            }}>{`background-color`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`#6e00ff`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <h4>{`B3: Spacing columns`}</h4>
      <p>{`Adding onto B1 and B2, if you need spacing (whitespace) between columns, your solution should depend on whether the columns have a background image or color, or if there’s a border between the columns.`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`No backgrounds`}</strong>{` — in this case it is simplest to use `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`padding`}</code>{` to enforce the spacing. Thanks to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`box-sizing: border-box`}</code>{` you can keep the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`width`}</code>{` set at the total space a column occupies and add padding inside of that space. See how spacing is established in the example provided with B1a. above. Alternatively, set `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`justify-content:space-between`}</code>{` on the row instead to make this even simpler.`}</li>
        <li parentName="ul"><strong parentName="li">{`No backgrounds but a border in-between`}</strong>{` — you can actually follow the same pattern as above and use padding in combination with `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`width`}</code>{`, `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`border settings`}</code>{`, and `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`box-sizing:border-box`}</code>{`. See how spacing is established in the example provided with B1b above. It might not be helpful to use `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`justify-content:space-between`}</code>{` in this situation.`}</li>
        <li parentName="ul"><strong parentName="li">{`Backgrounds, with or without border`}</strong>{` — in this situation, you need to use `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`padding`}</code>{` to provide the desired inset spacing between the text content and the outer edges of the background. This is much simpler if you’re also using `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`box-sizing:border-box`}</code>{` since this allows both padding and border to be included in the width setting. But spacing outside the edges needs to be accomplished with `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`margin`}</code>{`. See how spacing is established in the example provided with B2 above. Here the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`justify-content:space-between`}</code>{` setting is once again very helpful.`}</li>
      </ul>
      <h4>{`B4: Centered columns`}</h4>
      <p>{`Flexbox makes centering columns very easy. The `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`justify-content:center`}</code>{` setting achieves this affect. In this case `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`flex:1`}</code>{` on the items will lead them to all be the same width but fill the whole row. If this is not desired use a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`width`}</code>{` setting on the items instead.`}</p>
      <p><img parentName="p" {...{
          "src": "/images/layout-patterns-B4.png",
          "alt": null
        }}></img></p>
      <p><strong parentName="p">{`HTML:`}</strong>{` Here the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`header ul`}</code>{` gets treated like a row as opposed to the whole `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<header>`}</code>{`. We're showing the larger context for your benefit.`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`header`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h1`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Lorem Ipsum`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h1`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`nav`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`ul`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`row`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`#`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Cras Diam`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`#`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Vel Dolor`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`#`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Egestas`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`#`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Protitor`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`nav`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`header`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p><strong parentName="p">{`CSS:`}</strong>{` Here we use the very helpful `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`center`}</code>{` setting for `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`justify-content`}</code>{` to quickly ensure the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`li`}</code>{` elements align on the center axis. Note that instead of `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`flex:1`}</code>{` though we need a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`width`}</code>{` value to ensure the items don't just fill the container. We use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`auto`}</code>{` to just allow the elements to be as wide as their contents demand. We then use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`margin`}</code>{` to enforce even spacing between the items.`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`header ul`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`justify-content`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`center`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`header li`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`auto`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`list-style`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`none`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`0 20px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`padding`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`0`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <h4>{`B5: Restricted-width row with columns`}</h4>
      <p>{`Building off of the other patterns so far, sometimes you’ll have a row that has a restricted width that also has columns in it. In such a case, you should first set up the restricted width settings on the row using the patterns described above in A1–4, then treat the columns as needed inside of the resulting space as described in B1–3. Also be careful where you apply the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`.row`}</code>{` so that it is on the direct parent element for the columns.`}</p>
    </Subpage>
    <Subpage slug="tiles" mdxType="Subpage">
      <h3>{`Layout Pattern C: Tiles`}</h3>
      <p>{`Tiles can be implemented where a set of elements form a predictable series of rows and columns, typically where all items occupy the same width and height, such as in a product thumbnail list.`}</p>
      <p>{`This is easy to achieve with flex box by setting `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`flex-flow:row wrap`}</code>{` on the row. In this case you should be sure to use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`width`}</code>{` settings on the items rather than `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`flex`}</code>{` settings.`}</p>
      <p><img parentName="p" {...{
          "src": "/images/layout-patterns-C.png",
          "alt": null
        }}></img></p>
      <p><strong parentName="p">{`HTML:`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`ul`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`row products`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`#`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`...`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`b`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`name`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Sed convallis`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`b`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`span`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`price`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`$5.99`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    ...
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p><strong parentName="p">{`CSS:`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`.products`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`justify-content`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`space-between`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`flex-flow`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`row wrap`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.products li`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`300px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin-bottom`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`30px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`list-style`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`none`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`Note as well that the items inside each of these tiles can be set up using flex box tiling. The three items—image, name, price—have a predictable pattern of size. The image fills the "row" while the name and price sit beside each other. Check out the simple settings to achieve this:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`.products li`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`300px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin-bottom`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`30px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`list-style`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`none`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`display`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`flex`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`justify-content`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`space-between`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.products img`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`100%`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin-bottom`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`20px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.products .name`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin-left`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`10px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`195px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`.products .price`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin-right`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`85px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <h4>{`Centered tiles`}</h4>
      <p>{`Following the same idea as in B4, tiles inside can easily be centered  by placing `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`justify-content:center`}</code>{` on the row.`}</p>
      <h4>{`Tile exceptions`}</h4>
      <p>{`Chances are that the item at the beginning or end of each row will need some overrides applied in order to achieve the desired effect. First apply desired overall tile settings for width, height, margin, and padding.`}</p>
      <p>{`In order to affect the first item on each row in the tile set you can use this formula for the pseudo-class selector...`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`tile_item_selector:nth-child(xn+1)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` ... `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span></span></pre></div>
      <p>{`...where `}<em parentName="p">{`x`}</em>{` is the number of items in a row.`}</p>
      <p>{`To affect the last item in each row in the tile set use this formula for the pseudo-class selector...`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`tile_item_selector:nth-child(xn)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` ... `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span></span></pre></div>
      <p>{`...where `}<em parentName="p">{`x`}</em>{` is the number of items in a row.`}</p>
    </Subpage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      